import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import {
  addFamilyAdmin,
  addFamilyImg,
  checkIfFamilyNameExists,
  removeFamilyImg,
  updateFamilyAdmin,
} from "../../actions/families/families";
import { Row, Col, Image } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
import FileDropZone from "../sub/FileDropZone";
import UploadCustomImgModal from "../sub/modals/UploadCustomImgModal";
import Notification from "../sub/Notification";
import InputTextElement from "../sub/InputTextElement";
import InputLength from "../../enums/InputLength";
import MenuButton from "../sub/bootstrap/MenuButton";
import ConfirmationModal from "../sub/modals/ConfirmationModal";

class FamilyModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.family ? this.props.family.name : "",
      nameError: "",
      file: [],
      modal: null,
    };
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openCustomFamilyImgModal(family, currentImage, mode) {
    const successCallback = () => {
      Notification({
        type: "success",
        description: this.props.intl.formatMessage({
          id: "Family.Image.Updated",
        }),
      });

      this.props.closeModal();
    };
    const onComplete = (newImage) => {
      if (mode === "upload") {
        return this.uploadFamilyImage(
          `${family._id}.png`,
          newImage,
          successCallback,
        );
      }
      this.removeFamilyImage(family._id, successCallback);
    };
    const databaseDefaultProductImgUrl = `${APIUrl.getDefaultFamilyImg}${family.name}/?token=${APIUrl.jwtToken}`;
    this.setState({
      modal: (
        <UploadCustomImgModal
          closeModal={() => this.props.closeModal()}
          defaultImage={databaseDefaultProductImgUrl}
          currentImage={currentImage}
          textInfo={
            <FormattedMessage
              id={
                mode === "upload"
                  ? "Imagery.Message.Category.1"
                  : "Imagery.Message.Category.2"
              }
            />
          }
          mode={mode}
          itemName={this.state.name}
          onComplete={onComplete}
        />
      ),
    });
  }

  uploadFamilyImage(familyName, familyImage, successCallback) {
    const sendFamilyImage = (successCallback) => {
      if (!familyImage || familyImage.length === 0) return successCallback();

      let formData = new FormData();

      // Tell the backend that we want to use a custom images even if a database image exists
      formData.append("useCustomImage", true);

      for (let img of familyImage) {
        formData.append(familyName, img);
      }

      this.props.onAddFamilyImgs(
        this.props.mercurialId,
        formData,
        successCallback,
      );
    };

    sendFamilyImage(() => successCallback());
  }

  removeFamilyImage(familyId, successCallback) {
    const removeFamilyImage = (successCallback) => {
      if (!familyId) return successCallback();
      this.props.onRemovefamilyImgs(
        this.props.mercurialId,
        { familyId: familyId },
        successCallback,
      );
    };

    removeFamilyImage(() => successCallback());
  }

  checkIfFamilyNameExists(value) {
    this.props.onCheckIfFamilyNameExists(
      { mercurialId: this.props.mercurialId, name: value },
      () =>
        this.setState({
          nameError: <FormattedMessage id={"Family.Already.Exists"} />,
        }),
      () => this.setState({ nameError: "" }),
    );
  }

  disabled() {
    return this.state.name === "" || this.state.nameError !== "";
  }

  onSubmit() {
    if (this.disabled()) return;

    const family = {
      name: this.state.name,
      mercurial_id: this.props.mercurialId,
      sortIndex: this.props.families.length,
    };

    if (this.state.file.length > 0) family.hasCustomImg = true;

    if (this.props.family) {
      family._id = this.props.family._id;
      family.sortIndex = this.props.family.sortIndex;

      return this.props.onUpdateFamilyAdmin(family, () =>
        this.props.closeModal(),
      );
    }

    this.props.onAddFamilyAdmin(family, (family) =>
      this.uploadFamilyImage(`${family._id}.png`, this.state.file, () => {
        Notification({
          type: "success",
          description: this.props.intl.formatMessage(
            this.props.family
              ? { id: "Family.Updated" }
              : { id: "Family.Created" },
          ),
        });

        this.props.closeModal();
      }),
    );
  }

  render() {
    const { family, currentImage } = this.props;
    return (
      <ConfirmationModal
        title={
          <FormattedMessage id={family ? "Family.Update" : "Add.Family"} />
        }
        onAccept={() => this.onSubmit()}
        confirmButtonDisabled={this.disabled()}
        onDecline={() => this.props.closeModal()}
      >
        <Row className="mb-3">
          <Col>
            <CustomLabel
              label={<FormattedMessage id="Name" />}
              htmlFor="name"
              displayAsCol
              required
            />
          </Col>
          <Col md={8}>
            <InputTextElement
              className="form-control"
              type="text"
              id="name"
              autoComplete="off"
              maxLength={InputLength.NAME}
              value={this.state.name}
              onChange={(e) =>
                this.setState({ name: e.target.value.toUpperCase() })
              }
              onBlur={() => this.checkIfFamilyNameExists(this.state.name)}
            />
            <div className="text-danger">
              <small>{this.state.nameError}</small>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <CustomLabel
              label={<FormattedMessage id="Image" />}
              htmlFor="image"
              displayAsCol
              required
            />
          </Col>
          <Col md={8}>
            {family && (
              <>
                <Row>
                  <Col className="text-center">
                    <Image thumbnail src={currentImage} alt={"family-img"} />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center mt-3">
                    {family.hasCustomImg ? (
                      <MenuButton
                        icon="trash"
                        size="sm"
                        variant="danger"
                        onClick={() =>
                          this.openCustomFamilyImgModal(
                            family,
                            currentImage,
                            "delete",
                          )
                        }
                      />
                    ) : (
                      <MenuButton
                        icon="upload"
                        size="sm"
                        onClick={() =>
                          this.openCustomFamilyImgModal(
                            family,
                            currentImage,
                            "upload",
                          )
                        }
                      >
                        <FormattedMessage id="Modify" />
                      </MenuButton>
                    )}
                  </Col>
                </Row>
              </>
            )}
            {!family && (
              <FileDropZone
                onDropFile={(file) => this.setState({ file: [file] })}
                acceptedExtensions={["jpg", "png", "jpeg", "webp"]}
                multiple={false}
              />
            )}
          </Col>
        </Row>
        {this.state.modal}
      </ConfirmationModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    families: state.families,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddFamilyAdmin: (data, successCallback) =>
      dispatch(addFamilyAdmin(data, successCallback)),
    onUpdateFamilyAdmin: (data, successCallback) =>
      dispatch(updateFamilyAdmin(data, successCallback)),
    onCheckIfFamilyNameExists: (data, existsCallback, noExistsCallback) =>
      dispatch(checkIfFamilyNameExists(data, existsCallback, noExistsCallback)),
    onRemovefamilyImgs: (mercurialId, data, successCallback) =>
      dispatch(removeFamilyImg(mercurialId, data, successCallback)),
    onAddFamilyImgs: (mercurialId, data, successCallback) =>
      dispatch(addFamilyImg(mercurialId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(FamilyModal));
