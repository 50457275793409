import React from "react";
import { connect } from "react-redux";
import StringUtil from "../../../../util/StringUtil";
import { FormattedMessage } from "react-intl";
import {
  deleteAuditRoom,
  sortRoom,
} from "../../../../actions/settings/auditRooms/admin/auditRooms";
import Icon from "../../../sub/Icon.js";
import ActionMenu from "../../../sub/ActionMenu";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class AuditRoomsRowAdmin extends React.Component {
  onSort(room, direction) {
    if (!room) return;

    direction = direction === "up" || direction === "down" ? direction : "down";

    const data = {
      auditRoomId: room._id,
      direction: direction,
      updatedValue:
        direction === "down" ? room.sortIndex + 1 : room.sortIndex - 1,
    };

    this.props.onSortRoom(data);
  }

  render() {
    const { user } = this.props;
    const hidden = this.props.auditRoom.hidden || false;
    const hasHomeOption = user.access_pharmamad || user.access_module_home;

    const auditRoomName = hidden ? (
      <span className="text-danger">
        {this.props.auditRoom.name.toUpperCase()} <Icon icon="eye-slash" />
      </span>
    ) : (
      this.props.auditRoom.name.toUpperCase()
    );

    const modalTitle = <FormattedMessage id="Confirm" />;
    const modalContent = (
      <React.Fragment>
        <FormattedMessage
          id={
            hasHomeOption
              ? "Room.Type.Remove.Confirmation"
              : "Type.Category.Remove.Confirmation"
          }
        />
        <br />
        <br />
        <div className="card text-white bg-danger">
          <div className="card-header">
            <Icon icon="triangle-exclamation" className="me-2 text-white" />
            <FormattedMessage id="Warning" />
          </div>
          <div className="card-body">
            <p className="card-text">
              <FormattedMessage
                id={
                  hasHomeOption
                    ? "AuditRoom.Delete.Warning.Content"
                    : "Category.Delete.Warning.Content"
                }
              />
            </p>
          </div>
        </div>
      </React.Fragment>
    );

    const successCallback = () =>
      this.props.onDeleteAuditRoom({
        auditRoomId: this.props.auditRoom._id,
      });

    const disabledUp = this.props.auditRoom.sortIndex === 1;
    const disabledDown =
      this.props.auditRooms.length <= this.props.auditRoom.sortIndex;

    const menuItems = [];

    menuItems.push(
      {
        icon: "arrow-up",
        action: (e) => {
          !disabledUp && this.onSort(this.props.auditRoom, "up");
        },
        disabled: disabledUp,
        text: <FormattedMessage id="Move.Up" />,
      },
      {
        icon: "arrow-down",
        action: (e) => {
          !disabledDown && this.onSort(this.props.auditRoom, "down");
        },
        disabled: disabledDown,
        text: <FormattedMessage id="Move.Down" />,
      },
      {
        icon: "pen-to-square",
        action: () => this.props.openEditModal(this.props.auditRoom),
        text: <FormattedMessage id="Modify" />,
      },
      {
        icon: "trash",
        action: () =>
          this.props.openConfModal(modalTitle, modalContent, successCallback),
        text: <FormattedMessage id="Delete" />,
      },
    );

    const menuAction = <ActionMenu items={menuItems} />;

    return (
      <tr
        key={this.props.key}
        className="order-tr order-tr tablelist row-striped"
      >
        <td className="align-middle">{auditRoomName}</td>
        {hasHomeOption && (
          <td className="align-middle">
            {this.props.auditRoom.default_text &&
              StringUtil.truncate(this.props.auditRoom.default_text, 150)}
          </td>
        )}

        <td className="align-middle text-center">
          <MenuButton
            icon="eye"
            onClick={(e) =>
              this.props.displaysDmAssociatedModal(this.props.auditRoom)
            }
            hover={<FormattedMessage id="Display.Associations" />}
          />
        </td>

        <td className="align-middle text-center">{menuAction}</td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditRooms: state.auditRooms,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSortRoom: (data, successCallback) =>
      dispatch(sortRoom(data, successCallback)),
    onDeleteAuditRoom: (data, successCallback) =>
      dispatch(deleteAuditRoom(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditRoomsRowAdmin);
