import React from "react";
import { FormattedMessage } from "react-intl";
import AuditDMRowAdmin from "./AuditDMRowAdmin";
import { Alert } from "react-bootstrap";

function AuditDMTableAdmin({
  auditDMs,
  auditRoomId,
  openEditModal,
  openConfModal,
}) {
  const auditDMsRowNode =
    auditDMs &&
    auditDMs.map((auditDM, index) => {
      // /!\ Warning : we are checking here a value from a Mongo aggregate (sent by the backend : AuditDMDAO.getAll)
      // Necessary if we store multiple room_id for the same DM
      if (auditDM.currentRoom._id === auditRoomId) {
        return (
          <AuditDMRowAdmin
            key={index}
            auditDM={auditDM}
            openEditModal={(auditDM) => openEditModal(auditDM)}
            openConfModal={(title, content, successCallback) =>
              openConfModal(title, content, successCallback)
            }
          />
        );
      }
      return [];
    });

  const hasDM = auditDMs.find((dm) => dm.currentRoom._id === auditRoomId);

  return (
    <>
      {hasDM ? (
        <table className="table table-striped table-bordered tablee4mad mt-3">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Material.DM" />
              </th>
              <th>
                <FormattedMessage id="Refundable" />
              </th>
              <th>
                <FormattedMessage id="Type" />
              </th>
              <th className="text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{auditDMsRowNode}</tbody>
        </table>
      ) : (
        <Alert variant="info" className="mt-3">
          <FormattedMessage id="Empty.Dm" />
        </Alert>
      )}
    </>
  );
}

export default AuditDMTableAdmin;
