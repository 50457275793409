import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import OrderRowAdmin from "./OrderRowAdmin";
import ConfirmationModal from "../../sub/modals/ConfirmationModal";
import LoadingModal from "../../sub/modals/LoadingModal";
import SplitModal from "./SplitOrderModalAdmin";
import OrderStatus from "../../../enums/OrderStatus";
import Paginator from "../../sub/Paginator";
import DateUtil from "../../../util/DateUtil";
import Util from "../../../util/Util";
import AlertModal from "../../sub/modals/AlertModal";
import { mustGetEtatDossier } from "../../../actions/apiMust/apiMust";
import { lomacoGetEtatDocument } from "../../../actions/apiLomaco/apiLomaco";
import Roles from "../../../enums/Roles";
import MenuButton from "../../sub/bootstrap/MenuButton";
import { CrmProviders } from "../../../enums/CrmProviders";
import TableToolbar from "../../sub/bootstrap/TableToolbar";
import { Col, Form, FormSelect, Row } from "react-bootstrap";
import InputTextElement from "../../sub/InputTextElement";
import Select from "react-select";
import AssignOrderModal from "./AssignOrderModal";
import { updateAdmin } from "../../../actions/orders/orders";

class OrdersAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.defaultClientSelected = {
      value: "",
      label: `${this.props.intl.formatMessage({ id: "Client" })}: ${this.props.intl.formatMessage({ id: "All" })}`,
    };

    this.state = {
      modal: null,
      selectedClient: this.defaultClientSelected,
      referenceFilter: "",
      dateStartFilter: "",
      dateEndFilter: "",
      statusFilter: "",
      crm_sync_order_status:
        this.props.company.crm?.options?.must_allow_sync_order_status === true
          ? this.props.company.crm?.options?.must_allow_sync_order_status
          : this.props.company.crm?.options?.lomaco?.allow_sync_order_status ===
              true
            ? this.props.company.crm?.options?.lomaco?.allow_sync_order_status
            : false,
    };

    this.paginator = new Paginator(this);
  }

  openSplitModal(order, successCallback) {
    this.setState({
      modal: (
        <SplitModal
          successCallback={successCallback}
          isOpen={true}
          order={order}
          closeConfModal={() => this.closeModal()}
        />
      ),
    });
  }

  openAssignModal(order) {
    const assignOrder = (clientId) => {
      const data = {
        updatedValue: clientId,
        updatedField: "client._id",
        orderId: order._id,
      };

      this.props.onUpdateAdmin(data, () => this.closeModal());
    };

    this.setState({
      modal: (
        <AssignOrderModal
          assignOrder={(clientId) => assignOrder(clientId)}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openErrorModal(title, content, successCallback) {
    this.setState({
      modal: (
        <AlertModal
          title={title}
          content={content}
          onClose={() => successCallback}
        />
      ),
    });
  }

  openConfModal(
    title,
    content,
    successCallback,
    variant = "primary",
    isMandatoryConfirmation = false,
  ) {
    const callback = () => {
      successCallback();
      this.closeModal();
    };

    this.setState({
      modal: (
        <ConfirmationModal
          variant={variant}
          isOpen={true}
          title={title}
          onAccept={callback}
          onDecline={() => this.closeModal()}
          mandatoryConfirmation={isMandatoryConfirmation}
        >
          {content}
        </ConfirmationModal>
      ),
    });
  }

  openSuccessModal(title, content) {
    this.setState({
      modal: (
        <AlertModal
          variant="success"
          title={title}
          content={content}
          onClose={() => this.closeModal()}
        />
      ),
    });
  }

  openLoadingModal(title, content, event) {
    this.setState({
      modalnext: (
        <LoadingModal
          isOpen={true}
          title={title}
          content={content}
          event={event}
          closeModal={() => this.closeModalNext()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  closeModalNext() {
    this.setState({ modalnext: null });
  }

  sortByDateDesc(order1, order2) {
    if (order1.creation_date > order2.creation_date) return -1;
    if (order1.creation_date < order2.creation_date) return 1;
    return 0;
  }

  getProductsByOrder(order, products) {
    var currentOrderProducts = [];

    for (let op of order.products) {
      for (let p of products) {
        if (op.id_product === p._id) {
          currentOrderProducts.push(p);
        }
      }
    }

    return currentOrderProducts;
  }

  setStartDateFilter = (date) => {
    this.setState({
      dateStartFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0,
      ),
    });
  };

  setEndDateFilter = (date) => {
    this.setState({
      dateEndFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        23,
        59,
        59,
        999,
      ),
    });
  };

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.selectedClient.value) ||
      !Util.emptyString(this.state.referenceFilter) ||
      !Util.emptyString(this.state.dateStartFilter) ||
      !Util.emptyString(this.state.dateEndFilter) ||
      !Util.emptyString(this.state.statusFilter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      selectedClient: this.defaultClientSelected,
      referenceFilter: "",
      dateStartFilter: "",
      dateEndFilter: "",
      statusFilter: "",
    });
  }

  syncDossierModal() {
    if (this.props.company.crm.software === CrmProviders.MUST.software) {
      this.openConfModal(
        <FormattedMessage
          id="API.CRM.Synchronize.With"
          values={{ crmSoftware: CrmProviders.MUST.software }}
        />,
        <FormattedMessage
          id="API.CRM.Synchronize.Order.Status.With.Content"
          values={{ crmSoftware: CrmProviders.MUST.software }}
        />,
        () => {
          this.openLoadingModal(
            <FormattedMessage id="Sync.In.Progress" />,
            <FormattedMessage id="Sync.In.Progress.Warning" />,
            () => {
              this.props.onGetEtatDossier(
                this.props.company._id,
                () => {
                  this.closeModalNext();
                  this.openSuccessModal(
                    <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Success.Title" />,
                    <FormattedMessage
                      id="API.CRM.Synchronize.Order.Status.With.Success.Content"
                      values={{ crmSoftware: CrmProviders.MUST.software }}
                    />,
                  );
                },
                (result) => {
                  this.closeModalNext();
                  this.openErrorModal(
                    <FormattedMessage id="API.CRM.Sync.Error" />,
                    <FormattedMessage
                      id={
                        result.data.error_code === "512"
                          ? result.data.message
                          : "API.CRM.Synchronize.Order.Status.With.Error.Content"
                      }
                    />,
                    () => {},
                  );
                },
              );
            },
          );
        },
      );
    } else if (
      this.props.company.crm.software === CrmProviders.LOMACO.software
    ) {
      this.openConfModal(
        <FormattedMessage
          id="API.CRM.Synchronize.With"
          values={{ crmSoftware: CrmProviders.LOMACO.software }}
        />,
        <FormattedMessage
          id="API.CRM.Synchronize.Order.Status.With.Content"
          values={{ crmSoftware: CrmProviders.LOMACO.software }}
        />,
        () => {
          this.openLoadingModal(
            <FormattedMessage id="Sync.In.Progress" />,
            <FormattedMessage id="Sync.In.Progress.Warning" />,
            () => {
              this.props.onGetEtatDocument(
                this.props.company._id,
                () => {
                  this.closeModalNext();
                  this.openSuccessModal(
                    <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Success.Title" />,
                    <FormattedMessage
                      id="API.CRM.Synchronize.Order.Status.With.Success.Content"
                      values={{ crmSoftware: CrmProviders.LOMACO.software }}
                    />,
                  );
                },
                () => {
                  this.closeModalNext();
                  this.openErrorModal(
                    <FormattedMessage id="API.CRM.Sync.Error" />,
                    <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Error.Content" />,
                    () => {},
                  );
                },
              );
            },
          );
        },
      );
    }
  }

  getSelectOptions() {
    const options = [];
    const clients = this.props.clients.filter((c) =>
      this.props.orders.find((o) => o.client?._id === c._id),
    );

    options.push(this.defaultClientSelected);

    for (const c of clients) {
      options.push({
        value: c._id,
        label: Util.formatFullName(c.first_name, c.name),
      });
    }
    return options;
  }

  render() {
    if (!this.props.orders || this.props.orders.length === 0) {
      return (
        <TableToolbar message={<FormattedMessage id="Admin.Empty.Orders" />} />
      );
    }

    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false, software: crmSoftware = null } = this
      .props.company.crm
      ? this.props.company.crm
      : {};
    const { must_allow_sync_order_status: mustAllowSyncOrderStatus = false } =
      this.props.company?.crm?.options ? this.props.company?.crm?.options : {};
    const { allow_sync_order_status: lomacoAllowSyncOrderStatus = false } = this
      .props.company?.crm?.options?.lomaco
      ? this.props.company?.crm?.options.lomaco
      : {};

    let orders = this.props.orders.sort(this.sortByDateDesc);

    this.paginator.init(this.props.orders.length);

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var i = 0;
    let ordersNode = orders.map((order) => {
      if (this.state.selectedClient && this.state.selectedClient.value !== "") {
        if (order.client?._id !== this.state.selectedClient.value) return null;
      }

      if (this.state.referenceFilter && this.state.referenceFilter !== "") {
        if (order.ref.indexOf(this.state.referenceFilter) === -1) return null;
      }

      if (this.state.dateStartFilter && this.state.dateStartFilter !== "") {
        if (
          new Date(order.creation_date) < new Date(this.state.dateStartFilter)
        )
          return null;
      }

      if (this.state.statusFilter && this.state.statusFilter !== "") {
        if (order.status !== Number(this.state.statusFilter)) return null;
      }

      if (order.status === OrderStatus.TBV) return null;

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <OrderRowAdmin
          key={order._id}
          order={order}
          products={order.products}
          productsForCrm={this.getProductsByOrder(order, this.props.products)}
          mercurials={this.props.mercurials}
          openConfModal={(
            title,
            content,
            successCallback,
            variant,
            isMandatoryConfirmation,
          ) =>
            this.openConfModal(
              title,
              content,
              successCallback,
              variant,
              isMandatoryConfirmation,
            )
          }
          openSuccessModal={(title, content) =>
            this.openSuccessModal(title, content)
          }
          openSplitModal={(order, successCallback) =>
            this.openSplitModal(order, successCallback)
          }
          openAssignModal={(order) => this.openAssignModal(order)}
          openErrorModal={(title, content, successCallback) =>
            this.openErrorModal(title, content, successCallback)
          }
          limit={this.props.limit && true}
        />
      );
    });

    var statusNode = Object.values(OrderStatus).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "Order.Status." + option })}
        </option>
      );
    });

    return (
      <React.Fragment>
        {!this.props.limit && (
          <TableToolbar>
            <Form.Group as={Row} className="align-items-center">
              <Col>
                <Select
                  className={
                    this.state.selectedClient.value && "filtered-result"
                  }
                  classNamePrefix={
                    this.state.selectedClient.value && "react-select"
                  }
                  placeholder={this.props.intl.formatMessage({ id: "Client" })}
                  options={this.getSelectOptions()}
                  noOptionsMessage={() => <FormattedMessage id={"No.Result"} />}
                  onChange={(option) =>
                    this.setState({
                      selectedClient: option,
                    })
                  }
                  value={this.state.selectedClient}
                />
              </Col>
              <Col>
                <InputTextElement
                  id="search_reference"
                  type="text"
                  className={this.state.referenceFilter && "filtered-result"}
                  onChange={(e) => {
                    this.setState({
                      referenceFilter: e.target.value.toUpperCase(),
                    });
                  }}
                  disabled={disableFormInput}
                  value={this.state.referenceFilter}
                  placeholder={this.props.intl.formatMessage({
                    id: "Reference",
                  })}
                />
              </Col>
              <Col>
                <InputTextElement
                  id="search_date"
                  type="date"
                  className={this.state.dateStartFilter && "filtered-result"}
                  onChange={(e) => {
                    this.setStartDateFilter(e.target.value);
                  }}
                  disabled={disableFormInput}
                  value={DateUtil.toyyyyMMdd(this.state.dateStartFilter)}
                  placeholder={this.props.intl.formatMessage({ id: "Date" })}
                />
              </Col>
              <Col>
                <FormSelect
                  id="search_status"
                  className={this.state.statusFilter && "filtered-result"}
                  onChange={(e) => {
                    this.setState({ statusFilter: e.target.value });
                  }}
                  disabled={disableFormInput}
                  value={this.state.statusFilter}
                  placeholder={this.props.intl.formatMessage({ id: "Status" })}
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "Status" })}
                    {": "}
                    {this.props.intl.formatMessage({ id: "All" })}
                  </option>
                  {statusNode}
                </FormSelect>
              </Col>
              <Col>
                <MenuButton
                  onClick={() => this.resetSearchFields()}
                  hover={
                    this.areResultsFiltered() &&
                    !disableFormInput && <FormattedMessage id="Remove.Filter" />
                  }
                  variant={
                    this.areResultsFiltered() ? "info" : "outline-secondary"
                  }
                  icon="filter"
                  disabled={!this.areResultsFiltered() || disableFormInput}
                />
              </Col>

              <Col className="text-end">
                {this.props.user.role === Roles.ADMIN &&
                  crmEnabled &&
                  (mustAllowSyncOrderStatus || lomacoAllowSyncOrderStatus) &&
                  this.state.crm_sync_order_status && (
                    <MenuButton
                      variant="info"
                      icon="arrow-right-arrow-left"
                      className="mr-auto"
                      placement="bottom"
                      onClick={() => this.syncDossierModal()}
                    >
                      <FormattedMessage
                        id="API.CRM.Sync"
                        values={{ crmSoftware: crmSoftware }}
                      />
                    </MenuButton>
                  )}
              </Col>
            </Form.Group>
          </TableToolbar>
        )}

        <table
          className={
            this.props.limit
              ? "table table-striped tablee4mad pointer-events-none"
              : "table table-striped tablee4mad"
          }
        >
          <thead>
            <tr className="align-items-center">
              <th scope="col" className="col">
                <FormattedMessage id="Client" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Reference" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Date" />
              </th>
              {!this.props.limit && (
                <th scope="col" className="col">
                  <FormattedMessage id="Expected.Delivery.Date" />
                </th>
              )}
              <th scope="col" className="col">
                <FormattedMessage id="Total.Excl.Tax" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Order.Status" />
              </th>
              {crmEnabled && (
                <th scope="col" className="col-1 text-center">
                  <FormattedMessage id="API.CRM.Sync.Short" />
                </th>
              )}
              {!this.props.limit && (
                <th scope="col" className="col-1 text-center">
                  <FormattedMessage id="Actions" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>{ordersNode}</tbody>
        </table>

        {!this.props.limit && this.paginator.render()}

        {this.state.modal}
        {this.state.modalnext}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    clients: state.clients,
    collaborators: state.collaborators,
    products: state.products,
    mercurials: state.mercurials,
    company: state.company,
    ordersSettingsAdmin: state.ordersSettingsAdmin,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEtatDossier: (data, successCallback, failureCallback) =>
      dispatch(mustGetEtatDossier(data, successCallback, failureCallback)),
    onGetEtatDocument: (data, successCallback, failureCallback) =>
      dispatch(lomacoGetEtatDocument(data, successCallback, failureCallback)),
    onUpdateAdmin: (data, successCallback) =>
      dispatch(updateAdmin(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(OrdersAdmin));
