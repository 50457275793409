import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Modal, Form, Row, Alert } from "react-bootstrap";
import Util from "../../../util/Util";
import FileDropZone from "../FileDropZone";
import CustomLabel from "../CustomLabel";
import PropTypes from "prop-types";
import Icon from "../Icon";
import DocumentsDb from "../../documents/DocumentsDb";
import InputLength from "../../../enums/InputLength";
import InputTextElement from "../InputTextElement";
import PopoverHelper from "../bootstrap/PopoverHelper";
import MenuButton from "../bootstrap/MenuButton";

const propTypes = {
  data: PropTypes.object.isRequired,
  documentType: PropTypes.string.isRequired,
  addDataDocument: PropTypes.any.isRequired,
  updateData: PropTypes.any.isRequired,
  close: PropTypes.any.isRequired,
  messageId: PropTypes.string.isRequired,
};

/**
 * Modal to add a document with title
 * @param {Array} data Object where document will be store
 * @param {String} documentType string to define what type of document it will be
 * @param {any} addDataDocument fonction to store file
 * @param {any} updateData fonction to update data
 * @param {any} close fonction to close modal
 * @param {string} messageId id for formatted message to display modal title
 */
class AddDocumentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: "",
      file: null,
      disabled: false,
      modal: null,
      isUploadedFromDocumentDb: false,

      fileError: false,
    };
  }

  disabled() {
    return (
      Util.emptyString(this.state.label) ||
      !this.state.file ||
      this.state.disabled ||
      this.state.fileError
    );
  }

  onDropFile(file) {
    this.setState({ file: file });

    if (
      this.props.data[this.props.documentType].find(
        (p) => p.file_name === file.name,
      )
    ) {
      this.setState({
        fileError: (
          <FormattedMessage
            id="File.Already.Exists"
            values={{ fileName: file.name }}
          />
        ),
      });
    } else {
      this.setState({ fileError: false });
    }
  }

  onSubmit() {
    if (this.disabled()) return;

    const { data, documentType, updateData, addDataDocument } = this.props;

    if (!data) return;

    this.setState({ disabled: true });

    const successCallback = () => {
      let clonedData = Util.shallowClone(data);
      if (!clonedData[documentType]) clonedData[documentType] = [];

      clonedData[documentType].push({
        title: this.state.label,
        date: Date.now(),
        file_name: this.state.file.name,
      });

      const updatedData = {
        _id: clonedData._id,
        [documentType]: clonedData[documentType],
      };

      updateData(updatedData, () => this.props.close());
    };

    let formData = new FormData();
    formData.append(encodeURIComponent(this.state.file.name), this.state.file);
    addDataDocument(data._id, documentType, formData, successCallback);
  }

  addDocumentsDbFile() {
    const { data, documentType, updateData } = this.props;

    let clonedData = Util.shallowClone(data);
    if (!clonedData[documentType]) clonedData[documentType] = [];

    clonedData[documentType].push({
      title: this.state.label,
      date: Date.now(),
      file_name: this.state.file.file_name,
      is_shared_file: true,
      url: this.state.file.url,
    });

    const updatedData = {
      _id: clonedData._id,
      [documentType]: clonedData[documentType],
    };

    updateData(updatedData, () => this.props.close());
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openDocumentsDbModal() {
    this.setState({
      modal: (
        <Modal
          show={true}
          onHide={() => this.closeModal()}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.intl.formatMessage({ id: "Documents" })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DocumentsDb
              isUpload={true}
              setParentState={(file) =>
                this.setState({
                  file: file,
                  modal: null,
                  isUploadedFromDocumentDb: true,
                })
              }
              isPatient={true}
            />
          </Modal.Body>
        </Modal>
      ),
    });
  }

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.intl.formatMessage({ id: this.props.messageId })}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Title" })}
              htmlFor="label"
              help={<FormattedMessage id="Hint.Title.Document" />}
              displayAsCol
              required
            />
            <Col md={7}>
              <InputTextElement
                type="text"
                id="label"
                autoComplete="off"
                maxLength={InputLength.TITLE}
                value={this.state.label}
                onChange={(e) => this.setState({ label: e.target.value })}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 align-items-center">
            <Col className="text-center">
              <Alert variant="info">
                <FormattedMessage id="Choose.Document.Source" />
                <PopoverHelper>
                  <FormattedMessage id="Hint.Add.Document" />
                </PopoverHelper>
              </Alert>
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="align-items-center justify-content-center"
          >
            <Col md={5}>
              <div
                role="button"
                onClick={() => this.openDocumentsDbModal()}
                style={{
                  width: "100%",
                  borderWidth: "1px",
                  borderColor: "rgb(102, 102, 102)",
                  borderStyle: "dashed",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                <div className="text-center">
                  <Icon
                    disabled={this.state.isUploadedFromDocumentDb}
                    icon="folder-open"
                    size="6x"
                  />
                </div>
                <div className="small text-center">
                  <span>
                    {this.state.isUploadedFromDocumentDb ? (
                      this.state.file.file_name
                    ) : (
                      <FormattedMessage id="Select.From.Shared.Documents" />
                    )}
                  </span>
                </div>
              </div>
            </Col>

            <Col className="text-center">
              <h2>
                <strong className="text-uppercase">
                  <FormattedMessage id="Or" />
                </strong>
              </h2>
            </Col>

            <Col md={5}>
              <FileDropZone
                disabled={this.state.isUploadedFromDocumentDb}
                onDropFile={(file) => this.onDropFile(file)}
                acceptedExtensions={[
                  "pdf",
                  "doc",
                  "docx",
                  "txt",
                  "jpg",
                  "jpeg",
                  "png",
                  "webp",
                ]}
                multiple={false}
              />
            </Col>
          </Form.Group>

          <div className="w-100 text-center">
            <small className="text-danger">{this.state.fileError}</small>
          </div>

          {this.state.modal}
        </Modal.Body>
        <Modal.Footer>
          <MenuButton variant="secondary" onClick={() => this.props.close()}>
            <FormattedMessage id="Cancel" />
          </MenuButton>
          <MenuButton
            disabled={this.disabled()}
            onClick={() => {
              this.state.isUploadedFromDocumentDb
                ? this.addDocumentsDbFile()
                : this.onSubmit();
            }}
          >
            <FormattedMessage id="Add" />
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddDocumentModal.propTypes = propTypes;

export default injectIntl(AddDocumentModal);
