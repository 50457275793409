import React from "react";
import { connect } from "react-redux";
import FileDropZone from "../sub/FileDropZone";
import CustomLabel from "../sub/CustomLabel";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import FileUtil from "../../util/FileUtil";
import ExcelUtil from "../../util/ExcelUtil";
import APIUrl from "../../APIUrl";
import Axios from "axios";
import {
  Alert,
  Container,
  Row,
  ToggleButton,
  ToggleButtonGroup,
  Badge,
  Col,
} from "react-bootstrap";
import Icon from "../sub/Icon.js";
import { getProductsByMercurialIdAdmin } from "../../actions/products/products";
import MercurialslUtil from "../../util/mercurialsUtil";
import MenuButton from "../sub/bootstrap/MenuButton";
import ConfirmationModal from "../sub/modals/ConfirmationModal";

class MercurialImgsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      disabled: false,
      // Uploaded files
      summary: null,
      productFiles: null,
      declinedFiles: null,
      // Info
      productsWithNoImg: null,
      formVisibility: false,
      progressBarVisibility: true,
      progressbarMessage: (
        <FormattedMessage id="Import.Mercurial.Image.Check" />
      ),
      useSupplierReference: false,
      useCustomImage: false,
      files: [],
      mercurialId: this.props.mercurial._id,
    };
  }

  async componentDidMount() {
    if (!this.state.summary) {
      try {
        const response = await Axios.get(
          APIUrl.getMercImgOverview + this.props.mercurial._id,
        );
        this.setState({
          productsWithNoImg: response.data.productsWithNoImg,
          formVisibility: true,
          progressBarVisibility: false,
        });
      } catch (error) {
        console.error("Error fetching image overview:", error);
      }
    }

    if (
      this.props.mercurial._id !== "" &&
      this.props.products &&
      this.props.products.length !==
        this.getMercurial(this.props.mercurial._id).productsLength
    ) {
      this.props.onGetProductsByMercurialIdAdmin(this.props.mercurial._id);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.mercurial._id !== prevState.mercurialId &&
      this.props.mercurial._id !== "" &&
      this.props.products &&
      this.getProductsLengthByMercurialId(this.props.mercurial._id) !==
        this.getMercurial(this.props.mercurial._id).productsLength
    ) {
      this.props.onGetProductsByMercurialIdAdmin(this.props.mercurial._id);
    }
  }

  getProductsLengthByMercurialId(mercurialId) {
    let response = 0;
    for (let p of this.props.products) {
      if (p.mercurial_id === mercurialId) response = response + 1;
    }
    return response;
  }

  getMercurial(mercurialId) {
    let response = {};
    for (let mercurial of this.props.mercurials) {
      if (mercurial._id === mercurialId) response = mercurial;
    }
    return response;
  }

  close() {
    this.props.closeModal();
  }

  onComplete() {
    this.setState({
      disabled: true,
      formVisibility: false,
      progressBarVisibility: true,
      progressbarMessage: (
        <FormattedMessage id="Import.Mercurial.Image.Upload" />
      ),
    });

    this.props.onComplete(
      this.props.mercurial._id,
      this.state.productFiles,
      this.state.useCustomImage,
    );
  }

  onDropFiles(files) {
    this.setState({ files: files });

    let productFiles = [];
    let declinedFiles = [];

    let products = this.getMercurialProducts(this.props.mercurial);

    const usedReference = this.state.useSupplierReference ? "ref_frn" : "ref";

    outer: for (let file of files) {
      for (let product of products) {
        let fileNameWithoutExtension = FileUtil.withoutExtension(file.name);

        // Product img
        if (fileNameWithoutExtension === product[usedReference]) {
          let image = {
            file: file,
            reference: product.ref_frn,
          };

          productFiles.push(image);
          continue outer;
        }
      }

      declinedFiles.push(file);
    }

    this.setState({
      productFiles: productFiles,
      declinedFiles: declinedFiles,
    });
  }

  disabled() {
    return this.state.disabled || Util.emptyArray(this.state.productFiles);
  }

  // When an error occurs, allow download of generated import file
  downloadMissingImageList(products) {
    if (Util.typeOf(products) !== "Array" && products.length === 0) return;

    let excelData;
    let currentMercurial = new MercurialslUtil(this.props.company);

    excelData = ExcelUtil.toExcel(
      products,
      currentMercurial.getMercurialsMissingImagesMapping(),
    );
    let fileName = FileUtil.toFileName("missingImages");

    ExcelUtil.save(excelData, fileName);
  }

  closeModal() {
    this.setState({ modal: null });
  }

  displayMissingImgs() {
    // Users has dropped stuff, don't show
    // if (this.state.productFiles || this.state.declinedFiles) return false;

    return (
      this.state.productsWithNoImg && this.state.productsWithNoImg.length !== 0
    );
  }

  getMercurialProducts(mercurial) {
    let products = [];

    for (let product of this.props.products) {
      if (product.mercurial_id === mercurial._id) {
        products.push(product);
      }
    }

    return products;
  }

  toggle(scope) {
    switch (scope) {
      case "reference":
        // https://stackoverflow.com/questions/41278385/setstate-doesnt-update-the-state-immediately
        this.setState(
          { useSupplierReference: !this.state.useSupplierReference },
          function () {
            // re-check valid images if useSupplierReference mode has changed
            if (this.state.files.length) {
              this.onDropFiles(this.state.files);
            }
          },
        );
        break;
      case "image":
        this.setState({ useCustomImage: !this.state.useCustomImage });
        break;

      default:
        break;
    }
  }

  render() {
    // Alert box with info about how much missing imgs this mercurial has, with the list of the items
    let missingImgs = null;
    let importAnalysis = null;

    if (this.displayMissingImgs()) {
      let missingProductImgs = null;

      if (this.state.productsWithNoImg.length !== 0) {
        missingProductImgs = (
          <li>
            {this.state.productsWithNoImg.length}{" "}
            <FormattedMessage
              id="Mercurials.Missing.Product.Images"
              values={{
                plural: this.state.productsWithNoImg.length > 1 ? "s" : "",
              }}
            />
          </li>
        );
      }

      missingImgs = (
        <Alert variant="danger">
          <h4>
            <FormattedMessage id="Mercurials.Missing" /> :
          </h4>
          <ul>{missingProductImgs}</ul>
          <div className="text-center">
            <MenuButton
              variant="danger"
              icon="download"
              onClick={() => {
                this.downloadMissingImageList(this.state.productsWithNoImg);
              }}
            >
              <FormattedMessage id="Download.Missing.Image.List" />
            </MenuButton>
          </div>
        </Alert>
      );

      importAnalysis = (
        <Alert variant="info">
          <h4>
            <FormattedMessage
              id="Import.Analysis.Results"
              values={{ submitted_number: this.state.files.length }}
            />{" "}
            :
          </h4>
          <Row className="align-items-center mb-3">
            <Col>
              {this.state.productFiles && (
                <Badge bg="success" className="me-1">
                  <Icon icon="circle-check" className="me-1" />
                  {this.state.productFiles.length}{" "}
                  <FormattedMessage
                    id="Identified.Reference"
                    values={{
                      plural: this.state.productFiles.length > 1 ? "s" : "",
                    }}
                  />
                </Badge>
              )}
              {this.state.declinedFiles && (
                <Badge bg="danger" className="ms-1">
                  <Icon icon="fa-regular fa-circle-xmark" className="me-1" />
                  {this.state.declinedFiles.length}{" "}
                  <FormattedMessage
                    id="Mercurials.Invalid.Images"
                    values={{
                      plural: this.state.declinedFiles.length > 1 ? "s" : "",
                    }}
                  />
                </Badge>
              )}
            </Col>
          </Row>
        </Alert>
      );
    }

    return (
      <>
        <ConfirmationModal
          size={"lg"}
          title={<FormattedMessage id="Mercurials.Import.Imgs" />}
          onAccept={() => this.onComplete()}
          confirmButtonLabel={<FormattedMessage id="Import" />}
          confirmButtonDisabled={this.disabled()}
          cancelButtonLabel={<FormattedMessage id="Skip.This.Step" />}
          onDecline={() => this.close()}
        >
          {this.state.progressBarVisibility && (
            <div className="text-center mb-5 ">
              <Icon
                icon="gear"
                size="3x"
                className="fa-spin text-success mb-3"
              />
              <div className="progress" style={{ height: "30px" }}>
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: "100%" }}
                >
                  <strong>{this.state.progressbarMessage}</strong>
                </div>
              </div>
            </div>
          )}

          {this.state.formVisibility && (
            <div>
              {this.state.productFiles || this.state.declinedFiles
                ? importAnalysis
                : missingImgs}
              <Container fluid className="mt-3">
                <Row className="align-items-center mb-3">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Used.Reference.System",
                    })}
                    htmlFor="name"
                    help={<FormattedMessage id="Hint.Image.Reference.System" />}
                    displayAsCol
                    required
                  />
                  <Col md={8}>
                    <ToggleButtonGroup
                      id="buttongroup"
                      name="buttongroup"
                      size="sm"
                      className="btn-block"
                      defaultValue={
                        this.state.useSupplierReference
                          ? "supplier"
                          : "internal"
                      }
                      onChange={() => this.toggle("reference")}
                    >
                      <ToggleButton
                        id="internal"
                        value={"internal"}
                        variant={
                          this.state.useSupplierReference
                            ? "light"
                            : "dark disabled"
                        }
                      >
                        <FormattedMessage id="Intern.Ref" />
                      </ToggleButton>

                      <ToggleButton
                        id="supplier"
                        value={"supplier"}
                        variant={
                          this.state.useSupplierReference
                            ? "dark disabled"
                            : "light"
                        }
                      >
                        <FormattedMessage id="Supplier.Ref" />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Col>
                </Row>
                <Row className="align-items-center mb-3">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Image.To.Display",
                    })}
                    htmlFor="name"
                    help={<FormattedMessage id="Hint.Image.To.Display" />}
                    displayAsCol
                    required
                  />
                  <Col md={8}>
                    <ToggleButtonGroup
                      id="buttongroup2"
                      name="buttongroup2"
                      size="sm"
                      className="btn-block"
                      defaultValue={
                        !this.state.useCustomImage ? "bank" : "custom"
                      }
                      onChange={() => this.toggle("image")}
                    >
                      <ToggleButton
                        id="bank"
                        value={"bank"}
                        variant={
                          this.state.useCustomImage ? "light" : "dark disabled"
                        }
                      >
                        <FormattedMessage id="Use.Database.Image" />
                      </ToggleButton>

                      <ToggleButton
                        id="custom"
                        value={"custom"}
                        variant={
                          this.state.useCustomImage ? "dark disabled" : "light"
                        }
                      >
                        <FormattedMessage id="Use.Custom.Image" />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Col>
                </Row>
                <Row className="align-items-center mb-3">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Drop.Visual",
                    })}
                    htmlFor="name"
                    help={
                      <FormattedMessage id="Hint.Mercurials.Upload.Imgs.Recommended.Dimensions" />
                    }
                    displayAsCol
                    required
                  />
                  <Col md={8}>
                    <FileDropZone
                      onDropFiles={(files) => this.onDropFiles(files)}
                      acceptedExtensions={["jpg", "jpeg", "png", "webp"]}
                      multiple={true}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </ConfirmationModal>
        {this.state.modal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mercurials: state.mercurials,
    products: state.products,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProductsByMercurialIdAdmin: (mercurialId) =>
      dispatch(getProductsByMercurialIdAdmin(mercurialId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MercurialImgsModal));
