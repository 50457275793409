import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Modal, Row } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
import InputTextElement from "../sub/InputTextElement";
import InputLength from "../../enums/InputLength";
import {
  findArticleMust,
  findDeclinaisonMust,
} from "../../actions/apiMust/apiMust";
import { findArticleLomaco } from "../../actions/apiLomaco/apiLomaco"; //findDeclinaisonLomaco
import { checkIfRefFrnExists } from "../../actions/products/products";
import MenuButton from "../sub/bootstrap/MenuButton";
import { CrmProviders } from "../../enums/CrmProviders";

class RefFrnModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ref_frn: "",
      refFrnError: "",
    };
  }

  disabled() {
    return this.state.ref_frn === "" || this.state.refFrnError !== "";
  }

  onSubmit() {
    let resp = {
      ref_frn: this.state.ref_frn,
    };
    let { enabled: crmEnabled = false, software: crmSoftware = null } = this
      .props.company.crm
      ? this.props.company.crm
      : {};
    if (this.props.isAddDeclination) {
      if (crmSoftware === CrmProviders.MUST.software) {
        console.log("declinaison");
        console.log(this.props.product);
        resp.codeArticle = this.props.product.crm_id;
        resp.ref = this.state.ref_frn;
        this.props.onGetMustDeclinaison(
          resp,
          (data) => {
            this.props.product.crm_id = data.crm_id;
            this.props.confirm(this.props.product);
          },
          (err) => {
            if (
              !err.data["FindDeclinaisonResult"]["Erreur"]["HasError"] &&
              err.data["FindArticleResult"]["NbArticle"] === 0
            ) {
              this.setState({ refFrnError: "Aucun rapprochement trouvé" });
            } else {
              this.setState({ refFrnError: "Une erreur est survenue" });
            }
          },
        );
      } else if (crmSoftware === CrmProviders.LOMACO.software) {
        // this.props.onGetLomacoDeclinaison(resp,(data)=>{this.props.confirm(data)},(err)=>{console.log(err.data["FindArticleResult"]["NbArticle"]);if(!err.data["FindArticleResult"]["Erreur"]["HasError"] && err.data["FindArticleResult"]["NbArticle"] === 0){this.setState({refFrnError:"Aucun rapprochement trouvé"})}else{this.setState({refFrnError:"Une erreur est survenue"})}})   //disponible dans un prochain ticket
      }
    } else {
      if (crmSoftware === CrmProviders.MUST.software) {
        this.props.onGetMustProduct(
          resp,
          (data) => {
            this.props.confirm(data);
          },
          (err) => {
            if (
              !err.data["FindArticleResult"]["Erreur"]["HasError"] &&
              err.data["FindArticleResult"]["NbArticle"] === 0
            ) {
              this.setState({ refFrnError: "Aucun rapprochement trouvé" });
            } else {
              this.setState({ refFrnError: "Une erreur est survenue" });
            }
          },
        );
      } else if (crmSoftware === CrmProviders.LOMACO.software) {
        // this.props.onGetLomacoProduct(resp,(data)=>{this.props.confirm(data)},(err)=>{console.log(err.data["FindArticleResult"]["NbArticle"]);if(!err.data["FindArticleResult"]["Erreur"]["HasError"] && err.data["FindArticleResult"]["NbArticle"] === 0){this.setState({refFrnError:"Aucun rapprochement trouvé"})}else{this.setState({refFrnError:"Une erreur est survenue"})}})   //disponible dans un prochain ticket
      }
    }
  }

  checkIfRefFrnExists(value) {
    this.props.onCheckIfRefFrnExists(
      { mercurialId: this.props.mercurialId, refFrn: value },
      () =>
        this.setState({
          refFrnError: <FormattedMessage id="Supplier.Ref.Already.Exists" />,
        }),
      () => this.setState({ refFrnError: "" }),
    );
  }
  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        backdrop="static"
        size="xl"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Add.RefFrn" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="mb-3">
            <Col>
              <CustomLabel
                label={<FormattedMessage id="Supplier.Ref" />}
                htmlFor="ref_frn"
                labelClassName="ps-0"
                required
              />
            </Col>
            <Col sm={8}>
              <InputTextElement
                className="form-control"
                type="text"
                id="ref_frn"
                autoComplete="off"
                maxLength={InputLength.ID}
                value={this.state.ref_frn}
                onChange={(e) => this.setState({ ref_frn: e.target.value })}
                onBlur={() => this.checkIfRefFrnExists(this.state.ref_frn)}
              />
              <div className="text-danger">
                <small>{this.state.refFrnError}</small>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <MenuButton
            variant="secondary"
            onClick={() => this.props.closeModal()}
          >
            <FormattedMessage id="Cancel" />
          </MenuButton>
          <MenuButton
            onClick={() => this.onSubmit()}
            disabled={this.disabled()}
          >
            <FormattedMessage id="Confirm" />
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    productsSettingsAdmin: state.productsSettingsAdmin,
    mercurials: state.mercurials,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckIfRefFrnExists: (data, existsCallback, noExistsCallback) =>
      dispatch(checkIfRefFrnExists(data, existsCallback, noExistsCallback)),
    onGetMustProduct: (data, existsCallback, noExistsCallback) =>
      dispatch(findArticleMust(data, existsCallback, noExistsCallback)),
    onGetLomacoProduct: (data, existsCallback, noExistsCallback) =>
      dispatch(findArticleLomaco(data, existsCallback, noExistsCallback)),
    onGetMustDeclinaison: (data, existsCallback, noExistsCallback) =>
      dispatch(findDeclinaisonMust(data, existsCallback, noExistsCallback)),
    // onGetLomacoDeclinaison: (data, existsCallback, noExistsCallback) =>
    //   dispatch(findDeclinaisonLomaco(data, existsCallback, noExistsCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(RefFrnModalAdmin));
