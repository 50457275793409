import Util from "./util/Util";
const rootUrl = Util.checkHttps(process.env.REACT_APP_BE_URL);
const loginUrl = Util.checkHttps(process.env.REACT_APP_LOGIN_BE_URL);

var APIUrl = {
  rootUrl: rootUrl,

  // ======================================================================
  // =========================== ALL ROLES ================================
  // ======================================================================

  // User
  getUser: rootUrl + "/user", // GET
  updateLang: rootUrl + "/user/lang/", // GET {lang = ["en", "fr"]}
  checkIfUsernameExists: rootUrl + "/user/check/username/", // GET {username}
  checkIfEmailExists: rootUrl + "/user/check/email/", // GET {email}
  checkIfUpdatedEmailExists: rootUrl + "/user/check/updatedemail/", // GET {updatedemail}
  checkIfEmailIsValid: loginUrl + "/validator/email/", // GET {email}
  updateUser: rootUrl + "/user/update", // POST {updatedField, updatedValue}
  getAdmin: rootUrl + "/user/getadmin", // GET {}
  getAdmins: rootUrl + "/user/getadmins",
  addFavorite: rootUrl + "/user/favorite/add", //POST
  deleteFavorite: rootUrl + "/user/favorite/delete", //POST
  sendWelcomeEmail: rootUrl + "/user/send/welcome", // POST {userId}

  // Rgpd
  createRgpd: rootUrl + "/rgpd/create", // POST
  getRgpd: rootUrl + "/rgpd/", // GET {}

  // Products
  getProducts: rootUrl + "/product", // GET
  getProductImg: rootUrl + "/product/img/", // GET {mercurialId, ref_frn, seed, ref, parentRefFrn, isDefault}
  getManyProducts: rootUrl + "/product/many", // POST {ids}
  getProductsByMercurialIdInactive:
    rootUrl + "/admin/product/mercurial/inactive/",
  getListProducts: rootUrl + "/product/list",
  toProductPDF: rootUrl + "/product/pdf",

  // Cart
  getCart: rootUrl + "/cart", // GET
  addProductToCart: rootUrl + "/cart/add", // POST {id_product, quantity}
  updateUrgent: rootUrl + "/cart/urgent", // POST {cartId, urgent}
  increaseQuantity: rootUrl + "/cart/inc", // POST {cartId}
  decreaseQuantity: rootUrl + "/cart/dec", // POST {cartId}
  deleteCartById: rootUrl + "/cart/delete", // POST {cartId}
  deleteAll: rootUrl + "/cart/delete/all", // POST

  //Coordination
  createMessage: rootUrl + "/coordination/add",
  getMessages: rootUrl + "/coordination",
  updateMessage: rootUrl + "/coordination/update",
  deleteMessage: rootUrl + "/coordination/remove",

  // coordination Notifications
  getMessageNotificationByPatientIds:
    rootUrl + "/coordination/notifications/patients",
  getMessageNotificationByPatientId:
    rootUrl + "/coordination/notifications/patient",
  updateSeenMessages: rootUrl + "/coordination/seen",

  // Order
  addOrder: rootUrl + "/order/add", // POST {comment}
  getOrders: rootUrl + "/order", // GET
  deleteOrder: rootUrl + "/order/delete", // POST {orderId}
  duplicate: rootUrl + "/order/duplicate", // POST {orderId}

  // Notifications
  getNotifs: rootUrl + "/notifs", // GET
  deleteNotif: rootUrl + "/notifs/delete", // POST {notifId}

  // Orders settings
  getOrdersSettings: rootUrl + "/settings/orders", // GET

  // Audit DM
  getAuditDMs: rootUrl + "/settings/dm", // GET
  getAuditDMImg: rootUrl + "/settings/dm/img/", // GET URL {auditDMId, roomId}

  // Audit Room
  getAuditRoomImg: rootUrl + "/settings/room/img/", // GET URL {auditRoomId, roomId}

  // Audits
  getAudits: rootUrl + "/audits", // GET
  getAuditsPDF: rootUrl + "/audits/file/", // GET URL
  getCheckAuditsPDF: rootUrl + "/audits/check/file/",

  // Audits Desk
  getDeskAudits: rootUrl + "/desk/audit", // GET
  getDeskAuditsPaginated: rootUrl + "/desk/audit/paginated", // GET
  deskAuditsCount: rootUrl + "/desk/audit/count", // GET

  // Desk Category
  getDeskCategoryImg: rootUrl + "/settings/deskcategory/img/", // GET URL {catId, roomId}
  getDeskProductImg: rootUrl + "/settings/deskproduct/img/",

  // Requests
  getRequests: rootUrl + "/requests", // GET
  addNewRequest: rootUrl + "/requests/add", // POST {precisions}
  getRequestFile: rootUrl + "/requests/file/", // URL {requestId} - POST {files}

  // Configurables
  getCollaboratorTypes: rootUrl + "/collaboratortype", // GET

  // Checkpoints
  getCheckpoints: rootUrl + "/checkpoint", // GET

  // Clients
  getClients: rootUrl + "/client", // GET
  getClientByPatientId: rootUrl + "/client/bypatientid",

  // Mercurial
  getMercurial: rootUrl + "/mercurial",

  // Interventions
  getInterventions: rootUrl + "/intervention", // GET
  getInterventionsPDF: rootUrl + "/intervention/file/", // GET URL

  // Contact
  contact: rootUrl + "/contacts", // POST {object, message}

  // General settings
  getGeneralSettings: rootUrl + "/generalsettings", // GET
  getGeneralSettingsLogo: rootUrl + "/asset/generalsettings/logo/", // URL /:companyId/:generalSettingsId - GET

  // Companies
  checkIfCompanyUrlExists: rootUrl + "/company/check/url/", // GET {url}
  getCompany: rootUrl + "/company", // GET

  // Patients
  getPatients: rootUrl + "/patient", // GET
  updPatient: rootUrl + "/patient/upd", // POST

  //Promo offer
  getPromoOperations: rootUrl + "/promo", // GET
  getPromoOperationFile: rootUrl + "/promo/file/", // POST{file}

  // Documents Database
  getDocumentsDbCategoriesTree: rootUrl + "/documentscategories/tree",
  getDocumentsDbCategoriesDescendants:
    rootUrl + "/documentscategories/descendants/",
  getDocumentsDbFilesByCategoryId: rootUrl + "/documentsdb/category", // POST {category_id}
  getDocumentsDbFile: rootUrl + "/documentsdb/file/", // POST {documentId}

  // Maintenances
  getMaintenances: rootUrl + "/maintenance", // GET
  getMaintenancesPDF: rootUrl + "/maintenance/file/", // GET URL

  // Audit settings
  getAuditSettings: rootUrl + "/auditsettings", // GET
  getAuditSettingsLogo: rootUrl + "/auditsettings/logo/", // URL {generalSettingsId} - GET

  // Audit Desk settings
  getAuditDeskSettings: rootUrl + "/auditdesksettings", // GET
  getAuditDeskSettingsLogo: rootUrl + "/auditdesksettings/logo/", // URL {generalSettingsId} - GET

  // Families
  getFamilies: rootUrl + "/families/", // POST {mercurialId}
  getFamilyImg: rootUrl + "/families/img/", // GET {mercurialId, familyId, familyName, seed?}
  getDefaultFamilyImg: rootUrl + "/families/img/default/", // GET {familyName, seed?}

  // ======================================================================
  // ====================== ADMIN and SALES_REP ===========================
  // ======================================================================

  // Companies
  deleteCompanyNotifAdmin: rootUrl + "/admin/company/notif/delete",

  // Products
  getProductsAdmin: rootUrl + "/admin/product", // GET
  getProductsByMercurialIdAdmin: rootUrl + "/admin/product/mercurial/",
  getListProductsAdmin: rootUrl + "/admin/product/list",
  addProductAdmin: rootUrl + "/admin/product/add",
  updateProductAdmin: rootUrl + "/admin/product/update", // POST {productId, updatedField, updatedValue}
  productsMarginUpdate: rootUrl + "/admin/product/update/bulk/margin",
  deleteProductAdmin: rootUrl + "/admin/product/delete", // POST {mercurialId, productId}
  checkIfRefFrnExists: rootUrl + "/admin/product/check/ref_frn", // POST {mercurialId, refFrn}
  checkIfDeclinationExists: rootUrl + "/admin/product/check/declination", // POST {mercurialId, designation}

  // Mercurials
  getMercurialsAdmin: rootUrl + "/admin/mercurial", // GET
  addMercurial: rootUrl + "/admin/mercurial/add", // POST {name, fileName, data, startDate, endDate}
  syncWithCrm: rootUrl + "/admin/mercurial/crmsync",
  deleteMercurial: rootUrl + "/admin/mercurial/delete", // POST {mercurialId}
  updateMercurial: rootUrl + "/admin/mercurial/update", // POST {mercurialId, data}
  updateMercurialStatus: rootUrl + "/admin/mercurial/updatestatus", // POST {mercurialId, data}
  addProductsImgs: rootUrl + "/admin/mercurial/imgs/products/", // URL {mercuriaId} - POST {imgs}
  removeProductsImgs: rootUrl + "/admin/mercurial/imgs/products/", // URL {mercuriaId} - POST {imgs}
  getMercImgOverview: rootUrl + "/admin/mercurial/img/overview/", // GET {mercurialId}

  // Orders
  getOrdersAdmin: rootUrl + "/admin/order", // GET
  updateAdmin: rootUrl + "/admin/order/update", // POST {status}
  deleteOrderAdmin: rootUrl + "/admin/order/delete", // POST {orderId}
  splitOrderAdmin: rootUrl + "/admin/order/split", // POST {orderId, productsAvailable, productsUnavailable}

  //API MUST
  mustFindCli: rootUrl + "/admin/must/client", // GET
  mustFindArticles: rootUrl + "/admin/must/articles", // GET
  mustFindArticle: rootUrl + "/admin/must/article", // GET
  mustFindDeclinaison: rootUrl + "/admin/must/declinaison", // GET
  mustGetEtatDossier: rootUrl + "/admin/must/getetatdossier", //GET
  syncOrderMustAdmin: rootUrl + "/admin/must/savedossier3CE",

  //API LOMACO
  lomacoFindArticles: rootUrl + "/admin/lomaco/articles", //GET
  lomacoFindArticle: rootUrl + "/admin/lomaco/article", //GET
  lomacoFindCli: rootUrl + "/admin/lomaco/client", //GET
  lomacoGetEtatDossier: rootUrl + "/admin/lomaco/getorderstatus",
  syncOrderLomacoAdmin: rootUrl + "/admin/lomaco/savedossier3CE",

  // Clients
  getClientsAdmin: rootUrl + "/admin/client", // GET
  getClient: rootUrl + "/admin/client/id", // POST {clientId}
  addClient: rootUrl + "/admin/client/add", // POST
  updClient: rootUrl + "/admin/client/update", // POST
  updateBulkClientsCatalog: rootUrl + "/admin/client/update/bulk/catalog", // POST
  getClientsTemplate: rootUrl + "/admin/client/template", // GET
  importClients: rootUrl + "/admin/client/import",
  deleteClient: rootUrl + "/admin/client/delete", // POST {clientId}

  // Type Prescribers
  getPrescriberTypes: rootUrl + "/prescribertype", // GET

  // Cart
  addProductToCartAdmin: rootUrl + "/admin/cart/add", // POST {id_product, quantity}

  // Settings
  getOrdersSettingsAdmin: rootUrl + "/admin/settings/orders", // GET
  updateOrderSettingAdmin: rootUrl + "/admin/settings/orders/update", // POST {orderSettingId, updatedField, updatedValue}
  updateShippingCostsSetting:
    rootUrl + "/admin/settings/orders/shipping/update", // POST {shippingCosts}

  // Settings product
  getProductsSettingsAdmin: rootUrl + "/admin/settings/products", // GET
  updateProductSettingAdmin: rootUrl + "/admin/settings/products/update", // POST {company_id, updatedField, updatedValue}

  // Rooms audit
  addAuditRoom: rootUrl + "/admin/settings/room/add", // POST {name, default_text}
  getAuditRooms: rootUrl + "/admin/settings/room", // GET
  getAuditRoom: rootUrl + "/admin/settings/room/check/", // GET {name}
  updateAuditRoom: rootUrl + "/admin/settings/room/update", // POST {auditRoomId, updatedField, updatedValue}
  deleteAuditRoom: rootUrl + "/admin/settings/room/delete", // POST {auditRoomId}
  addAuditRoomFile: rootUrl + "/admin/settings/room/file/", // URL {auditDMId, roomId} - POST {file}
  sortRoom: rootUrl + "/admin/settings/room/sort", // POST

  // Audit DM
  addAuditDM: rootUrl + "/admin/settings/dm/add", // POST {name, room, text, type, refundable}
  updateAuditDM: rootUrl + "/admin/settings/dm/update", // POST {auditDMId, updatedField, updatedValue}
  deleteAuditDM: rootUrl + "/admin/settings/dm/delete", // POST {auditDMId}
  getAuditDMByName: rootUrl + "/admin/settings/dm/check/", // GET {name, room_id}
  addAuditDMFile: rootUrl + "/admin/settings/dm/file/", // URL {auditDMId, roomId} - POST {file}
  sortCheckpoint: rootUrl + "/admin/settings/dm/checkpoint/sort", // POST
  addCheckpointToDM: rootUrl + "/admin/settings/dm/checkpoint/add", // POST {materialTypeId, checkpoint}
  updateCheckpoint: rootUrl + "/admin/settings/dm/checkpoint/update", // POST {checkpoint}
  deleteCheckpointFromDM: rootUrl + "/admin/settings/dm/checkpoint/delete", // POST {materialTypeId, checkpoint}
  addRoomToDM: rootUrl + "/admin/settings/dm/room/add", // POST {materialTypeId, checkpoint}
  deleteRoomFromDM: rootUrl + "/admin/settings/dm/room/delete", // POST {materialTypeId, checkpoint}
  getHomeTemplate: rootUrl + "/admin/settings/dm/template",
  exportHomeProducts: rootUrl + "/admin/settings/dm/export",
  importHomeProducts: rootUrl + "/admin/settings/dm/import",

  // Requests
  getAllRequests: rootUrl + "/admin/requests", // GET
  updateRequest: rootUrl + "/admin/requests/update", // POST {requestId, updatedField, updatedValue}
  addRequestFiles: rootUrl + "/admin/requests/files/", // URL {requestId} - POST {files}
  deleteRequest: rootUrl + "/admin/requests/delete", // DELETE
  deleteRequestFile: rootUrl + "/admin/requests/file/delete",

  // Maintenances
  addMaintenance: rootUrl + "/admin/maintenance/add", // POST {equipmentId, checkpoints}
  deleteMaintenance: rootUrl + "/admin/maintenance/delete", //POST

  // Interventions
  getInterventionsAdmin: rootUrl + "/admin/intervention", // GET
  deleteIntervention: rootUrl + "/admin/intervention/delete",
  sendIntervention: rootUrl + "/admin/intervention/send", // POST{emailsSendTo , patientId, interventionId}

  // Patients
  getPatientsAdmin: rootUrl + "/admin/patient", // GET
  addPatient: rootUrl + "/admin/patient/add", // POST
  importPatients: rootUrl + "/admin/patient/import",
  getPatientsTemplate: rootUrl + "/admin/patient/template", // GET
  deletePatient: rootUrl + "/admin/patient/remove", // POST
  addPatientDocument: rootUrl + "/admin/patient/document/file/add/", // POST {patientId}
  deletePatientDocument: rootUrl + "/admin/patient/document/file/delete/", // GET  {patientId, documentId}
  getPatientDocumentFile: rootUrl + "/admin/patient/document/file/", // GET  {patientId, documentId}
  getPatientEquipmentsReminded:
    rootUrl + "/admin/patient/equipment/reminder/renewal", // GET

  // Audit Desk
  getDeskAuditsAdmin: rootUrl + "/admin/desk/audit", // GET
  addDeskAudit: rootUrl + "/admin/desk/audit/add", // POST {name, default_text, specific_text}
  deleteDeskAudit: rootUrl + "/admin/desk/audit/delete", // POST {auditRoomId}
  sendDeskAudit: rootUrl + "/admin/desk/audit/send", // POST{emailsSendTo , patientId, auditId}
  addDeskCategory: rootUrl + "/admin/settings/desk/category/add", // POST {name, default_text, specific_text}
  getDeskCategories: rootUrl + "/admin/settings/desk/category", // GET
  getDeskCategory: rootUrl + "/admin/settings/desk/category/check/", // GET {name}
  sortCategory: rootUrl + "/admin/settings/desk/category/sort",
  updateDeskCategory: rootUrl + "/admin/settings/desk/category/update", // POST {auditRoomId, updatedField, updatedValue}
  deleteDeskCategory: rootUrl + "/admin/settings/desk/category/delete", // POST {auditRoomId}
  addDeskCategoryFile: rootUrl + "/admin/settings/desk/category/file/", // URL {auditDMId, roomId} - POST {file}

  // Audits
  getAuditAdmin: rootUrl + "/admin/audit", // GET
  deleteAudit: rootUrl + "/admin/audit/delete",
  sendAudit: rootUrl + "/admin/audit/send", // POST{emailsSendTo , patientId, auditId}

  // Documents Database
  createDocument: rootUrl + "/admin/documentsdb/add", // POST {data}
  addDocumentFile: rootUrl + "/admin/documentsdb/file/add", // POST {formData}
  updateDocument: rootUrl + "/admin/documentsdb/update", // POST {data}
  deleteDocument: rootUrl + "/admin/documentsdb/delete", // POST {dataId}
  addDocumentsDbCategory: rootUrl + "/admin/documentscategories/add", // POST {data}
  updateDocumentsDbCategory: rootUrl + "/admin/documentscategories/update", // POST {data}
  deleteDocumentsDbCategory: rootUrl + "/admin/documentscategories/delete", // POST {dataId}

  // Collaborators
  getCollaborators: rootUrl + "/admin/collaborator", // GET

  // Families
  addFamily: rootUrl + "/admin/families/add", // POST body
  updateFamily: rootUrl + "/admin/families/update", // POST body
  deleteFamily: rootUrl + "/admin/families/delete", // POST {familyId}
  sortFamily: rootUrl + "/admin/families/sort",
  checkIfFamilyNameExists: rootUrl + "/admin/families/check/name",
  addFamilyImg: rootUrl + "/admin/families/imgs/add/", // URL {mercuriaId} - POST {imgs}
  removeFamilyImg: rootUrl + "/admin/families/imgs/remove/",

  // ======================================================================
  // ============================= ADMIN ==================================
  // ======================================================================

  // General settings
  updateGeneralSettings: rootUrl + "/admin/generalsettings/update", // POST {updatedField, updatedValue}
  addLogo: rootUrl + "/admin/generalsettings/logo/", // URL {generalSettingsId} - POST {data}

  // DeskProduct
  addDeskProduct: rootUrl + "/admin/settings/desk/product/add", // POST {name, default_text, specific_text}
  getDeskProducts: rootUrl + "/admin/settings/desk/product", // GET
  getDeskProduct: rootUrl + "/admin/settings/desk/product/check/", // GET {name}
  updateDeskProduct: rootUrl + "/admin/settings/desk/product/update", // POST {auditRoomId, updatedField, updatedValue}
  deleteDeskProduct: rootUrl + "/admin/settings/desk/product/delete", // POST {auditRoomId}
  addDeskProductFile: rootUrl + "/admin/settings/desk/product/file/", // URL {auditDMId, roomId} - POST {file}
  importDeskProducts: rootUrl + "/admin/settings/desk/product/import",
  addDeskTypeofControl:
    rootUrl + "/admin/settings/desk/product/typeofcontrol/add", // URL {auditDMId, roomId} - POST {file}
  deleteDeskTypeofControl:
    rootUrl + "/admin/settings/desk/product/typeofcontrol/delete", // URL {auditDMId, roomId} - POST {file}
  updateDeskTypeofControl:
    rootUrl + "/admin/settings/desk/product/typeofcontrol/update", // URL {auditDMId, roomId} - POST {file}
  addDeskProductRelated:
    rootUrl + "/admin/settings/desk/product/productrelated/add",
  addDeskCategoryRelated:
    rootUrl + "/admin/settings/desk/product/categoryrelated/add",
  deleteDeskCategoryRelated:
    rootUrl + "/admin/settings/desk/product/categoryrelated/delete",
  deleteDeskProductRelated:
    rootUrl + "/admin/settings/desk/product/productrelated/delete",
  sortProduct: rootUrl + "/admin/settings/desk/product/sort",
  addProductFromCatalog:
    rootUrl + "/admin/settings/desk/product/productFromCatalog/add",
  sortProductFromCatalog:
    rootUrl + "/admin/settings/desk/product/productFromCatalog/sort",
  deleteProductFromCatalog:
    rootUrl + "/admin/settings/desk/product/productFromCatalog/delete",
  getDeskTemplate: rootUrl + "/admin/settings/desk/product/template",
  exportDeskProducts: rootUrl + "/admin/settings/desk/product/export",
  checkIfDeskProductRefExists:
    rootUrl + "/admin/settings/desk/product/check/ref", // POST {ref}

  // Configurables
  addCollaboratorType: rootUrl + "/admin/collaboratortype/add", // POST {name}
  updateCollaboratorType: rootUrl + "/admin/collaboratortype/update", // POST {name}
  removeCollaboratorType: rootUrl + "/admin/collaboratortype/remove", // POST {name}

  // Type Prescribers
  addPrescriberType: rootUrl + "/admin/prescribertype/add", // POST
  updatePrescriberType: rootUrl + "/admin/prescribertype/update", // POST
  deletePrescriberType: rootUrl + "/admin/prescribertype/delete", // POST

  // Collaborators
  addCollaborator: rootUrl + "/admin/collaborator/add", // POST {data}
  removeCollaborator: rootUrl + "/admin/collaborator/remove/", // POST {collaboratorId}
  updateCollaborator: rootUrl + "/admin/collaborator/update", // POST {collaboratorId, updatedField, updatedValue}
  addCollaboratorClient: rootUrl + "/admin/collaborator/client/add", // POST {collaboratorId, clientId}
  removeCollaboratorClient: rootUrl + "/admin/collaborator/client/remove", // POST {collaboratorId, clientId}

  // Audit settings
  addLogoAuditSettings: rootUrl + "/admin/auditsettings/logo/", // ADD
  // addAuditSettings : rootUrl + "/admin/auditsettings/add",                                       // POST
  updateAuditSettings: rootUrl + "/admin/auditsettings/update", // POST
  // deleteAuditSettings: rootUrl + "/admin/auditsettings/remove",                                  // POST
  toAuditPDF: rootUrl + "/admin/audit/addFile", // POST {html, css=[], header, footer}
  addAudit: rootUrl + "/admin/audit/add", // POST{audit}

  // Audit Desk settings
  addLogoAuditDeskSettings: rootUrl + "/admin/auditdesksettings/logo/", // ADD
  // addAuditDeskSettings : rootUrl + "/admin/auditdesksettings/add",                                       // POST
  updateAuditDeskSettings: rootUrl + "/admin/auditdesksettings/update", // POST
  // deleteAuditSettings: rootUrl + "/admin/auditsettings/remove",

  //Promo offer
  getPromoOperationsAdmin: rootUrl + "/admin/promo", // GET
  createPromoOperation: rootUrl + "/admin/promo/add", // POST{product_id, mercurial_id, startDate, endDate}
  addPromoOperationFile: rootUrl + "/admin/promo/file/", // POST{file}
  deletePromoOperation: rootUrl + "/admin/promo/delete/", // POST{operationPromotionalId}
  updatePromoOperation: rootUrl + "/admin/promo/update/", // POST{updatedField, updatedValue, promoOperationId}
};

var sessionJWT = sessionStorage.getItem("jwt");
if (sessionJWT) APIUrl.jwtToken = sessionJWT;

export default APIUrl;
