import React from "react";
import { connect } from "react-redux";
import FileDropZone from "../FileDropZone";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal, Alert, Badge, Container, Row, Col } from "react-bootstrap";
import ImageWithFallback from "../ImageWithFallback";
import Icon from "../Icon";
import MenuButton from "../bootstrap/MenuButton";

class UploadCustomImgModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      // Uploaded files
      customImg: [],
      disabledDropzone: false,
      previewImage: false,
      show: true,
    };
  }

  close() {
    this.setState({ show: false }, () => {
      // Add a timeout to allow smooth closing of the modal before executing the callback
      setTimeout(() => this.props.closeModal(), 100);
    });
  }

  onComplete() {
    this.setState({
      disabled: true,
      progressBarVisibility: true,
      progressbarMessage: <FormattedMessage id="Image.Upload.In.Progress" />,
    });

    this.props.onComplete(this.state.customImg);
  }

  onDropFile(files) {
    const that = this;

    var reader = new FileReader();
    reader.onloadend = function () {
      var imageSrc = reader.result;
      that.setState({
        customImg: [files],
        disabledDropzone: true,
        previewImage: imageSrc,
        disabled: false,
      });
    };

    reader.readAsDataURL(files);
  }

  abortCustomImage() {
    this.setState({
      customImg: [],
      disabledDropzone: false,
      previewImage: false,
    });
  }

  disabled() {
    return this.state.disabled || !this.state.customImg.length;
  }

  render() {
    const currentImage = this.props.currentImage;
    const defaultImage = this.props.defaultImage;

    let modalTitle = <FormattedMessage id="Image.Update" />;
    let modalWarning = (
      <Alert variant="success" className="text-justify">
        {this.props.textInfo}
      </Alert>
    );
    let confirmButton = !this.disabled() && (
      <MenuButton onClick={() => this.onComplete()}>
        <FormattedMessage id="Import" />
      </MenuButton>
    );

    if (this.props.mode === "delete") {
      modalTitle = <FormattedMessage id="Image.Delete" />;
      modalWarning = (
        <Alert variant="danger" className="text-justify">
          {this.props.textInfo}
        </Alert>
      );
      confirmButton = (
        <MenuButton variant="danger" onClick={() => this.onComplete()}>
          <FormattedMessage id="Confirm" />
        </MenuButton>
      );
    }

    return (
      <Modal
        show={this.state.show}
        onHide={() => this.close()}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitle} {this.props.itemName && <>"{this.props.itemName}"</>}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            {modalWarning}
            <Row>
              <Col md={5} className="text-center">
                {this.props.mode === "delete" ? (
                  <Badge bg="warning" className="mb-2">
                    <FormattedMessage id="Custom.Illustration" />
                  </Badge>
                ) : (
                  <Badge bg="success" className="mb-2">
                    <FormattedMessage id="Suggested.Illustration" />
                  </Badge>
                )}
                <div className="card-img-top d-block mx-auto img-thumbnail">
                  <ImageWithFallback
                    src={currentImage}
                    style={{ width: "200px" }}
                  />
                </div>
              </Col>

              <Col md={2} className="text-center">
                <Icon
                  className={
                    this.state.disabledDropzone
                      ? "fa-3x text-success pt-5 mt-5"
                      : this.props.mode === "delete"
                        ? "fa-3x text-danger pt-5 mt-5"
                        : "fa-3x text-secondary pt-5 mt-5"
                  }
                  icon={
                    this.state.disabledDropzone
                      ? "arrow-right"
                      : this.props.mode === "delete"
                        ? "arrow-right"
                        : "arrow-right"
                  }
                />
              </Col>

              {this.props.mode === "upload" && (
                <Col md={5} className="text-center">
                  <Badge bg="warning" className="mb-2">
                    <FormattedMessage id="Custom.Imagery" />
                  </Badge>
                  {!this.state.disabledDropzone && (
                    <FileDropZone
                      onDropFile={(file) => this.onDropFile(file)}
                      acceptedExtensions={["jpg", "jpeg", "png", "webp"]}
                      appearance="thumbnail"
                      multiple={false}
                    />
                  )}
                  {this.state.disabledDropzone && (
                    <div>
                      <div
                        className="card-img-top d-block mx-auto img-thumbnail"
                        style={
                          this.state.disabledDropzone && {
                            border: "1px solid #28a745",
                          }
                        }
                      >
                        <div className="position-absolute">
                          <MenuButton
                            icon="trash"
                            className="ms-auto"
                            onClick={() => this.abortCustomImage()}
                            variant="danger"
                            hover={<FormattedMessage id="Delete" />}
                          />
                        </div>
                        <ImageWithFallback
                          src={this.state.previewImage}
                          thumbnail
                        />
                      </div>
                    </div>
                  )}
                </Col>
              )}

              {this.props.mode === "delete" && (
                <div className="col-5 text-center">
                  <Badge bg="success" className="mb-2">
                    <FormattedMessage id="Suggested.Illustration" />
                  </Badge>
                  <div
                    className="card-img-top d-block mx-auto img-thumbnail"
                    style={{ border: "1px solid #dc3545" }}
                  >
                    <ImageWithFallback
                      src={defaultImage}
                      style={{ width: "200px" }}
                    />
                  </div>
                </div>
              )}
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <MenuButton variant="secondary" onClick={() => this.close()}>
            <FormattedMessage id="Cancel" />
          </MenuButton>
          {confirmButton}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(UploadCustomImgModal));
