import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Tab, Tabs } from "react-bootstrap";

import AuditSettings from "../auditSettings/AuditSettings";
import AuditRooms from "../auditRooms/AuditRooms";

class Audit extends React.Component {
  render() {
    const { intl, auditSettings, auditRooms, user } = this.props;
    const hasHomeOption = user.access_pharmamad || user.access_module_home;

    return (
      <React.Fragment>
        <Tabs
          defaultActiveKey={
            hasHomeOption ? "audit-settings" : "audit-audit-rooms"
          }
          id="auditTabs"
        >
          {hasHomeOption && (
            <Tab
              eventKey="audit-settings"
              title={intl.formatMessage({ id: "PDF.Params" })}
            >
              <AuditSettings auditSettings={auditSettings} />
            </Tab>
          )}

          <Tab
            eventKey="audit-audit-rooms"
            title={
              hasHomeOption
                ? intl.formatMessage({ id: "Audit.Rooms" })
                : intl.formatMessage({ id: "Categories.Desk" })
            }
          >
            <AuditRooms auditRooms={auditRooms} />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditSettings: state.auditSettings,
    auditRooms: state.auditRooms,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Audit));
