import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// https://fontawesome.com/v5/docs/web/use-with/react
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

library.add(fas, far);

export default class Icon extends React.Component {
  render() {
    /**
     * https://fontawesome.com/docs/web/use-with/react/
     * id : icon (element) id
     * icon : The name like provided by fontawesome
     * className : Additionnal bootstrap className
     * size : Icon size. If other than normal (none specified), must be one of these values (see availableSizes const)
     * fixedWidth : Define fixed width or not
     * spin : Make icon spin
     */
    const { id, icon, className, size, fixedWidth, spin, onClick } = this.props;

    const availableSizes = [
      "10x",
      "1x",
      "2x",
      "3x",
      "4x",
      "5x",
      "6x",
      "7x",
      "8x",
      "9x",
      "2xs",
      "lg",
      "sm",
      "xl",
      "xs",
      "2xl",
    ];

    const iconSize = availableSizes.includes(size) ? size : "1x";

    const iconElement = (
      <FontAwesomeIcon
        id={id}
        size={iconSize}
        icon={icon}
        spin={spin}
        className={className}
        fixedWidth={fixedWidth}
        onClick={onClick}
      />
    );

    return iconElement;
  }
}
