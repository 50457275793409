import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import AuditRoomsModalAdmin from "./AuditRoomsModalAdmin";
import AuditRoomsTableAdmin from "./AuditRoomsTableAdmin";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import TableToolbar from "../../../sub/bootstrap/TableToolbar";
import FileUtil from "../../../../util/FileUtil";
import APIUrl from "../../../../APIUrl";
import { Col, Dropdown, Row } from "react-bootstrap";
import Icon from "../../../sub/Icon";
import ImportExcelFileModal from "../../../sub/modals/ImportExcelFileModal";
import ExcelUtil from "../../../../util/ExcelUtil";
import ImportExcelFileColumnsModal from "../../../sub/modals/ImportExcelFileColumnsModal";
import HomeProductMapping from "../../../../util/HomeProductMapping";
import Notification from "../../../sub/Notification";
import Util from "../../../../util/Util";
import ObjectUtil from "../../../../util/ObjectUtil";
import { importHomeProducts } from "../../../../actions/settings/auditDM/admin/auditDM";
import { injectIntl } from "react-intl";
import InputLength from "../../../../enums/InputLength";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class AuditRooms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      fileName: null,
      fileData: null,
    };
  }

  openAuditRoomsModal() {
    this.setState({
      modal: (
        <AuditRoomsModalAdmin
          auditRooms={this.props.auditRooms}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  displaysDmAssociatedModal(auditRoom, target) {
    this.setState({
      modal: (
        <AuditRoomsModalAdmin
          isOpen={true}
          openModal={(auditRoomId, target) =>
            this.openModal(auditRoomId, target)
          }
          closeModal={() => this.closeModal()}
          auditRoomId={auditRoom._id}
          target={target}
        />
      ),
    });
  }

  openEditModal(auditRoom, target) {
    this.setState({
      modal: (
        <AuditRoomsModalAdmin
          isOpen={true}
          closeModal={() => this.closeModal()}
          auditRoomId={auditRoom._id}
          target={target}
        />
      ),
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={title}
          mandatoryConfirmation
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          {content}
        </ConfirmationModal>
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  downloadTemplate() {
    return FileUtil.dowloadFileHack(
      APIUrl.getHomeTemplate,
      "template_home",
      "xlsx",
    );
  }

  exportHomeProducts() {
    return FileUtil.dowloadFileHack(
      APIUrl.exportHomeProducts,
      "matrice_home",
      "xlsx",
    );
  }

  // Reset all previous import params in state when aborting import process
  abortAndCloseModal() {
    this.setState({
      fileName: null,
      fileData: null,
    });

    this.closeModal();
  }

  /**
   * Performs checks upon data.
   * We try to avoid sending data that will be rejected by the backend (because of Mongo/Mongoose field types for example)
   *
   * @param {*} columnsReferenceList
   */
  async checkDataIntegrity(fileData, columnsReferenceList, maxErrorToDisplay) {
    let errorsFound = [];
    const itemMapping = HomeProductMapping;
    var columns = {};

    // Store association between required column and matching column in the file (labels can be different if we didnt used automatching or partial automatching)
    for (let key of Object.keys(itemMapping)) {
      columns[key] = itemMapping[key];
    }

    let currentError;

    // Default maxlength for a value (if checked)
    // May be locally changed on some values
    let maxlength;

    // Add an error to the stack
    const addError = (error) => {
      if (Util.typeOf(error) === "Object") {
        errorsFound.push(error);
      }
    };

    // Loop through file rows
    for (let row of fileData) {
      if (errorsFound.length === maxErrorToDisplay) {
        break;
      }

      // Get current row keys
      let currentRowKeys = Object.keys(row);

      /**
       * CHECK SPECIAL FIELDS (where we know that value must be an integer or a float for example)
       */
      for (let key of currentRowKeys) {
        if (errorsFound.length === maxErrorToDisplay) {
          break;
        }

        let itemMappingReferenceKey = ObjectUtil.getKeyByValue(
          columnsReferenceList,
          key,
        );

        if (
          (itemMappingReferenceKey === "name" && Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "refundable" &&
            Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "type" && Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "room_1" && Util.emptyString(row[key]))
        ) {
          currentError = {
            numRow: row.__rowNum__ + 1,
            field: key,
            targetField: itemMapping[itemMappingReferenceKey],
            value: row[key],
            hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
          };

          addError(currentError);
          break;
        }

        switch (true) {
          case itemMappingReferenceKey === "name":
            maxlength = InputLength.NAME;
            // Check maxlength
            if (!Util.safeGenericString(row[key], 1, maxlength)) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;
          case itemMappingReferenceKey === "room_2":
          case itemMappingReferenceKey === "room_3":
          case itemMappingReferenceKey === "room_4":
          case itemMappingReferenceKey === "room_5":
          case itemMappingReferenceKey === "room_6":
          case itemMappingReferenceKey === "room_7":
          case itemMappingReferenceKey === "room_8":
          case itemMappingReferenceKey === "room_9":
          case itemMappingReferenceKey === "room_10":
          case itemMappingReferenceKey === "room_11":
          case itemMappingReferenceKey === "room_12":
          case itemMappingReferenceKey === "room_13":
          case itemMappingReferenceKey === "room_14":
          case itemMappingReferenceKey === "room_15":
            maxlength = InputLength.NAME;
            // Check maxlength
            if (!Util.emptyString(row[key]) && row[key].length > maxlength) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case itemMappingReferenceKey === "checkpoint_1":
          case itemMappingReferenceKey === "checkpoint_2":
          case itemMappingReferenceKey === "checkpoint_3":
          case itemMappingReferenceKey === "checkpoint_4":
          case itemMappingReferenceKey === "checkpoint_5":
          case itemMappingReferenceKey === "checkpoint_6":
          case itemMappingReferenceKey === "checkpoint_7":
          case itemMappingReferenceKey === "checkpoint_8":
          case itemMappingReferenceKey === "checkpoint_9":
          case itemMappingReferenceKey === "checkpoint_10":
          case itemMappingReferenceKey === "checkpoint_11":
          case itemMappingReferenceKey === "checkpoint_12":
          case itemMappingReferenceKey === "checkpoint_13":
          case itemMappingReferenceKey === "checkpoint_14":
          case itemMappingReferenceKey === "checkpoint_15":
          case itemMappingReferenceKey === "prescription_type_vte_text":
          case itemMappingReferenceKey === "prescription_type_loc_text":
          case itemMappingReferenceKey === "purchase_renewal":
          case itemMappingReferenceKey === "is_renewal_obligatory":
            maxlength = InputLength.TEXT;
            // Check maxlength
            if (!Util.emptyString(row[key]) && row[key].length > maxlength) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case itemMappingReferenceKey === "text":
            maxlength = InputLength.TEXT_LONG;
            // Check maxlength
            if (!Util.emptyString(row[key]) && row[key].length > maxlength) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          default:
            // Convert field to string to evaluate it
            let fieldValue = row[key].toString();
            // Double check string fields that may contain only spaces (so they are not considered as empty)
            // We trim the value in order to catch'em as well eventually
            if (fieldValue.trim() === "") {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: "",
                hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
              };

              addError(currentError);
            }
            break;
        }
      }
    }

    return errorsFound;
  }

  // Step 1 : Import excel file
  initStep1(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      mode: "create",
      modal: (
        <ImportExcelFileModal
          title={<FormattedMessage id="Import.Products.Type" />}
          templateFileUrl={APIUrl.getHomeTemplate}
          templateFileName={"template_home"}
          closeModal={() => this.abortAndCloseModal()}
          onComplete={(fileData) => this.initStep2(fileData)}
        />
      ),
    });
  }

  // Step 2 : File column mapping and data validation
  initStep2(file) {
    // Parse the excel file and then move on to next modal
    ExcelUtil.parse(file, (fileData) => {
      // Save the passed params
      this.setState({
        fileName: file.name,
        fileData: fileData,
      });

      // Close current modal
      this.closeModal();

      // Open next step modal
      this.setState({
        modal: (
          <ImportExcelFileColumnsModal
            closeModal={() => this.abortAndCloseModal()}
            fileData={fileData}
            onComplete={(columns) => this.initStep3(columns)}
            title={<FormattedMessage id="Import.Products.Type" />}
            itemMapping={HomeProductMapping}
            checkDataIntegrity={(columnsReferenceList, maxErrorToDisplay) =>
              this.checkDataIntegrity(
                fileData,
                columnsReferenceList,
                maxErrorToDisplay,
              )
            }
          />
        ),
      });
    });
  }

  // Step 3 : Send data to the backend
  initStep3(columns) {
    this.setState({
      columns: columns,
    });

    let successCallback;

    successCallback = () => {
      this.closeModal();

      Notification({
        type: "success",
        description: this.props.intl.formatMessage({
          id: "Import.Successful",
        }),
      });
    };

    // Send the products to the BE

    // First, fix data with correct columns
    const products = this.fixHomeProductsCols(this.state.fileData, columns);

    this.props.onImportHomeProducts(
      {
        fileName: this.state.fileName,
        products: products,
      },
      successCallback,
    );
  }

  // Perform checks on columns <-> data associations for products
  fixHomeProductsCols(products, columns) {
    let newProducts = [];
    for (let e of products) {
      var newProduct = {};
      for (let col of Object.keys(columns)) newProduct[col] = e[columns[col]];
      newProducts.push(newProduct);
    }

    return newProducts;
  }

  render() {
    const { auditRooms, user } = this.props;
    const hasHomeOption = user.access_pharmamad || user.access_module_home;

    const menuAction = (
      <Dropdown>
        <Dropdown.Toggle id="dropdownMenuLink">
          <FormattedMessage id="Actions" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {!this.props.clientId && (
            <>
              <Dropdown.Item onClick={(e) => this.openAuditRoomsModal()}>
                <Icon icon="circle-plus" className={"me-2"} />
                <FormattedMessage id="Add.Room" />
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={(e) => this.initStep1(e)}>
                <Icon icon="upload" className={"me-2"} />
                <FormattedMessage id="Import.Products.Type" />
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={(e) => this.exportHomeProducts()}>
                <Icon icon="download" className={"me-2"} />
                <FormattedMessage id="Export.Products.Type" />
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );

    return (
      <React.Fragment>
        <TableToolbar
          message={
            (!auditRooms || auditRooms.length <= 0) && (
              <FormattedMessage id="Empty.Audit.Room" />
            )
          }
        >
          <Row>
            <Col className="text-end">
              {hasHomeOption ? (
                menuAction
              ) : (
                <MenuButton
                  icon="circle-plus"
                  className="me-2"
                  onClick={(e) => this.openAuditRoomsModal()}
                >
                  <FormattedMessage id="Add.Categories" />
                </MenuButton>
              )}
            </Col>
          </Row>
        </TableToolbar>

        {this.props.auditRooms && this.props.auditRooms.length !== 0 && (
          <AuditRoomsTableAdmin
            auditRooms={this.props.auditRooms}
            displaysDmAssociatedModal={(auditRoom) =>
              this.openEditModal(auditRoom, "dmassociated")
            }
            openEditModal={(data) => this.openEditModal(data)}
            openConfModal={(title, content, successCallback) =>
              this.openConfModal(title, content, successCallback)
            }
          />
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditRooms: state.auditRooms,
    auditEquipments: state.auditDMs,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onImportHomeProducts: (data, successCallback, failureCallback) =>
      dispatch(importHomeProducts(data, successCallback, failureCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AuditRooms));
